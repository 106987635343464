import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundStyled = styled.p`
  width: 100%;
  height: 100%;
  p {
    margin: 43vh auto 10vh auto;
    @media (max-width: 799px) {
      margin: 30vh auto 10vh auto;
    }
    font-size: 2.8vh;
    text-align: center;
  }
`

const NotFoundPage = () => (
  <NotFoundStyled id="not-found">
    <SEO title="404: Not found" />
    <Link to="/">
      <p>
        page not found
        <br />
        error 404
        <br />
        <br />
        Back to the home page
      </p>
    </Link>
  </NotFoundStyled>
)

export default NotFoundPage
